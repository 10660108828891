import { createSlice } from "@reduxjs/toolkit";
import { IFaxingState } from "./faxingTypes";
import { getCurrentDate } from "helpers/formatDateTime";

const initialFaxingState: IFaxingState = {
	isDeleteFaxDialogueOpen: false,
	isNewFaxDialogueOpen: false,
	isFaxDetailInfoDialogueOpen: false,
	isFaxDetailsSelected: false,
	isSortingPopUpOpen: false,
	tabSelected: "inbound",
	faxLists: [],
	inboundFaxLists: [],
	outboundFaxLists: [],
	scheduledFaxLists: [],
	sortFax: "-created_at",
	isDateFilterPopUpOpen: false,
	filterDate: {
		from_date: "01/01/2000",
		to_date: getCurrentDate(),
	},
	faxNumberLists: [],
	selectedAttachment: [],
	isUploadingFiles: false,
	selectedFax: {},
	representationData: {},
};

const faxingSlice = createSlice({
	name: "faxing",
	initialState: initialFaxingState,

	reducers: {
		setIsDeleteFaxDialogueOpen(state, action) {
			state.isDeleteFaxDialogueOpen = action.payload;
		},
		setIsNewFaxDialogueOpen(state, action) {
			state.isNewFaxDialogueOpen = action.payload;
		},
		setIsFaxDetailInfoDialogueOpen(state, action) {
			state.isFaxDetailInfoDialogueOpen = action.payload;
		},
		setIsFaxDetailsSelected(state, action) {
			state.isFaxDetailsSelected = action.payload;
		},
		setIsSortingPopUpOpen(state, action) {
			state.isSortingPopUpOpen = action.payload;
		},
		setTabSelected(state, action) {
			state.tabSelected = action.payload;
		},
		setFaxLists(state, action) {
			state.faxLists = action.payload;
		},
		setInboundFaxLists(state, action) {
			state.inboundFaxLists = action.payload;
		},
		setOutboundFaxLists(state, action) {
			state.outboundFaxLists = action.payload;
		},
		setScheduledFaxLists(state, action) {
			state.scheduledFaxLists = action.payload;
		},
		setSortFax(state, action) {
			state.sortFax = action.payload;
		},
		setIsDateFilterPopUpOpen(state, action) {
			state.isDateFilterPopUpOpen = action.payload;
		},
		setFilterDate(state, action) {
			state.filterDate = action.payload;
		},
		setFaxNumberLists(state, action) {
			state.faxNumberLists = action.payload;
		},
		setSelectedAttachment(state, action) {
			state.selectedAttachment = action.payload;
		},
		setIsUploadingFiles(state, action) {
			state.isUploadingFiles = action.payload;
		},
		setSelectedFax(state, action) {
			state.selectedFax = action.payload;
		},
		setRepresentationData(state, action) {
			state.representationData = action.payload;
		},
	},
});

export const {
	setIsDeleteFaxDialogueOpen,
	setIsNewFaxDialogueOpen,
	setIsFaxDetailInfoDialogueOpen,
	setIsFaxDetailsSelected,
	setIsSortingPopUpOpen,
	setTabSelected,
	setFaxLists,
	setInboundFaxLists,
	setOutboundFaxLists,
	setScheduledFaxLists,
	setSortFax,
	setIsDateFilterPopUpOpen,
	setFilterDate,
	setFaxNumberLists,
	setSelectedAttachment,
	setIsUploadingFiles,
	setSelectedFax,
	setRepresentationData,
} = faxingSlice.actions;

export default faxingSlice.reducer;
